<template>
  <div id="app">
    <myHeader />
    <router-view></router-view>
    <myFooter />
  </div>
</template>

<script>
import myFooter from './components/myFooter.vue'
import myHeader from './components/myHeader.vue'
export default {
  name: 'App',
  components: {
    myHeader,
    myFooter
  },
  methods: {

  },
}
</script>

<style>
body {
  margin: 0px;
  font-size: 16px;
  background-color: #f7f8f9;
}
</style>
