<template>
  <div class="card">
    <div class="t2">{{title}}</div>
    <div
      class="desc"
      @click="jump()"
    >{{desc}}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    jump: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .card {
    width: 32%;
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
    border-radius: 1rem;
    background: #fff;
    margin-bottom: 1.2rem;
    text-align: center;
    .t2 {
      margin-bottom: 2rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .desc {
      margin-bottom: 2rem;
      font-size: 1.25rem;
      color: #111925;
      font-weight: 500;
      color: #409eff;
      line-height: 1;
      cursor: pointer;
    }
    .desc:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .card {
    width: 32%;
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
    border-radius: 1rem;
    background: #fff;
    margin-bottom: 1.2rem;
    text-align: center;
    .t2 {
      margin-bottom: 2rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .desc {
      margin-bottom: 2rem;
      font-size: 1.25rem;
      color: #111925;
      font-weight: 500;
      color: #409eff;
      line-height: 1;
      cursor: pointer;
    }
    .desc:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .card {
    width: 49%;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    border-radius: 0.5rem;
    background: #fff;
    margin-bottom: 0.7rem;
    text-align: center;
    .t2 {
      margin-bottom: 1.125rem;
      font-size: 1.25rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .desc {
      margin-bottom: 1.5rem;
      font-size: 0.75rem;
      color: #111925;
      font-weight: 500;
      color: #409eff;
      line-height: 1.5;
      cursor: pointer;
    }
    .desc:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .card:last-child {
    display: none;
  }
}
</style>