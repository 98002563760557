<template>
  <div class="wechat_applet">
    <div class="t2">微信小程序</div>
    <div class="body">
      <img :src="img" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      img: require('../../assets/trends/xcx.png'),
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .wechat_applet {
    max-width: 1280px;
    padding: 0px 20px;
    margin: 100px auto 20px auto;
    .t2 {
      margin-bottom: 2rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
        max-width: 640px;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .wechat_applet {
    max-width: 1280px;
    padding: 0px 20px;
    margin: 80px auto 20px auto;
    .t2 {
      margin-bottom: 2rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
        max-width: 640px;
      }
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .wechat_applet {
    max-width: 1280px;
    padding: 0px 20px;
    margin: 80px auto 20px auto;
    .t2 {
      margin-bottom: 1.125rem;
      font-size: 1.25rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
        max-width: 640px;
      }
    }
  }
}
</style>