import { render, staticRenderFns } from "./footer_nav_item.vue?vue&type=template&id=b2476462&scoped=true"
import script from "./footer_nav_item.vue?vue&type=script&lang=js"
export * from "./footer_nav_item.vue?vue&type=script&lang=js"
import style0 from "./footer_nav_item.vue?vue&type=style&index=0&id=b2476462&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2476462",
  null
  
)

export default component.exports