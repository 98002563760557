<template>
  <div class="footer_nav_item">
    <div class="title">{{ data.title }}</div>
    <div class="container">
      <div
        class="column"
        v-for="(col,index) in chunkArray(data.list,3)"
        :key="'col'+index"
      >
        <div
          v-for="(item,itemIndex) in col"
          :key="item.title"
          :class="getClass(item)"
          @click="() => { 
            item.click && item.click()
          }"
          @mouseenter="() => { if (item.isPop) { $set(item, 'popShow', true) } }"
          @mouseleave="() => { if (item.isPop) { $set(item, 'popShow', false) } }"
        >
          {{ item.title }}

          <transition
            name="fade"
            v-if="item.isPop"
          >
            <div
              v-if="item.popShow"
              :ref="'item'+itemIndex"
              class="item_pop"
            >
              <img
                :src="item.img"
                style="width: 100%;height: 100%"
              />
              <span class="icon iconfont">&#xe6a4;</span>
            </div>
          </transition>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
    getClass(item) {
      let arr = ["item"];
      if (item.isLink) {
        arr.push("link");
      }
      return arr.join(" ")
    },
    chunkArray(array, size) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    }
  },
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
.footer_nav_item {
  min-width: 130px;
  .title {
    --tw-text-opacity: 1;
    font-size: 0.875rem;
    margin-bottom: 28px;
    color: rgb(17 25 37 / var(--tw-text-opacity));
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    .column {
      /*flex-basis: 100%;  每列占据100%宽度 */
      display: flex;
      flex-direction: column;
      .item {
        flex-basis: 33.333%; /* 每行占据100% / 3 */
        color: rgb(101, 106, 114);
        font-size: 0.875rem;
        margin-bottom: 1rem;
        line-height: 1;
        min-width: 12em;
        cursor: pointer;
        position: relative;
        .item_pop {
          width: 120px;
          height: 120px;
          background-color: #fff;
          position: absolute;
          top: calc(-50px);
          left: -135px;
          border-radius: 10px;
          padding: 10px;

          box-shadow: 0px 5px 10px 2px rgba(83, 83, 83, 0.2); /* 鼠标悬浮时的阴影 */
          .icon {
            font-size: 15px;
            position: absolute;
            right: -12px;
            top: calc(60px - 10px);
            color: #fff;
          }
        }
      }
      .item:hover {
        .item_pop {
        }
      }
      .link:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>