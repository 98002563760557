<template>
  <div class="player-container">
    <div class="t2">{{ data && data.title }}</div>
    <div class="t3">{{ data && data.trends_desc }}</div>
    <!--4.使用组件-->
    <div
      v-if="data"
      class="videoBody"
      @click="videoClick()"
    >
      <VueAliplayer
        ref="vueAliplayer"
        :vid="vid"
        :source="source"
        :options="options"
      ></VueAliplayer>
    </div>

  </div>
</template>

<script>

import VueAliplayer from 'vue-aliplayer-v2';
export default {
  components: {
    VueAliplayer,
  },
  props: {},
  data() {
    return {
      data: null,

      /**
 *  对aliplayer进行配置
 * **/
      vid: 'pc_jPrismPlayer',
      source: "https://media.darenlaiye.com/20240722-172325.mp4",
      //vue-aliplayer-v2提供配置器为options， 所有配置项必须在options，否则无法正常显示
      options: {
        width: '100%',                //播放器宽度
        height: '100%',              //播放器高度
        isLive: false,                //播放内容是否为直播，直播时会禁止用户拖动进度条。
        playsinline: true,             //H5 是否内置播放
        useH5Prism: true,              //指定使用 H5 播放器。
        rePlay: false,                 //播放器自动循环播放.
        preload: true,                 //播放器自动加载，目前仅 h5 可用。
        controlBarVisibility: 'hover', //控制面板的实现，默认为‘hover’。可选的值为：‘click’、‘hover’、‘always’。
        autoplay: false,               //播放器是否自动播放，在移动端 autoplay 属性会失效。Safari11 不会自动开启自动播放如何开启。
        enableSystemMenu: true,        //是否允许系统右键菜单显示，默认为 false。
        loadDataTimeout: 5,            //缓冲多长时间后，提示用户切换低清晰度，默认：20 秒。
        showBarTime: '10000',          //控制栏自动隐藏时间（ms）。
        cover: this.posterUrl,         //播放器默认封面图片，请填写正确的图片 url 地址。需要 autoplay 为’false’时，才生效。Flash 播放器封面也需要开启允许跨域访问。
        disableSeek: true,            //禁用进度条的 Seek，默认为 false，仅 Flash 支持。
        //自定义 不要进度条
        skinLayout:                   //说明：功能组件布局配置，不传该字段使用默认布局。传 false 隐藏所有功能组件，请参照皮肤定制。
          [
            {
              "name": "bigPlayButton",
              "align": "blabs",
              "x": 'calc(50% - 32px)',
              "y": 'calc(50% - 32px)',
            },
            {
              "name": "H5Loading",
              "align": "cc"
            },
            {
              "name": "errorDisplay",
              "align": "tlabs",
              "x": 0,
              "y": 0
            },
            {
              "name": "infoDisplay"
            },
            {
              "name": "tooltip",
              "align": "blabs",
              "x": 0,
              "y": 56
            },
            {
              "name": "thumbnail"
            },
            {
              "name": "controlBar",
              "align": "blabs",
              "x": 0,
              "y": 0,
              "children": [
                {
                  "name": "progress",
                  "align": "blabs",
                  "x": 0,
                  "y": 44
                },
                {
                  "name": "playButton",
                  "align": "tl",
                  "x": 15,
                  "y": 12
                },
                {
                  "name": "timeDisplay",
                  "align": "tl",
                  "x": 10,
                  "y": 7
                },
                {
                  "name": "fullScreenButton",
                  "align": "tr",
                  "x": 10,
                  "y": 12
                },
                // {
                //   "name": "subtitle",
                //   "align": "tr",
                //   "x": 15,
                //   "y": 12
                // },
                {
                  "name": "volume",
                  "align": "tr",
                  "x": 5,
                  "y": 10
                }
              ]
            }
          ],
      }

    };
  },
  watch: {},
  computed: {},
  methods: {
    videoClick() {
      // $refs.vueAliplayer.pause()
      if (this.$refs.vueAliplayer.getStatus() == "ready" || this.$refs.vueAliplayer.getStatus() == "pause") {
        this.$refs.vueAliplayer.play()
      } else if (this.$refs.vueAliplayer.getStatus() == "playing") {
        this.$refs.vueAliplayer.pause()
      }
      console.log(this.$refs.vueAliplayer.getStatus());
    }
  },
  created() { },
  mounted() {
    this.source = this.$route.params.data.content_link;
    this.data = this.$route.params.data;
  }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .player-container {
    max-width: 1280px;
    padding: 0px 20px;
    margin: 100px auto 20px auto;
    .t2 {
      margin-bottom: 1rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: bolder;

      line-height: 1;
    }
    .t3 {
      margin-bottom: 2rem;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.6;
      color: #646971;
    }
    .videoBody {
      height: 724px;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .player-container {
    max-width: 1280px;
    padding: 0px 20px;
    margin: 80px auto 20px auto;
    .t2 {
      margin-bottom: 1rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: bolder;

      line-height: 1;
    }
    .t3 {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.6;
      color: #646971;
    }
    .videoBody {
      height: 724px;
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .player-container {
    max-width: 1280px;
    padding: 0px 20px;
    margin: 80px auto 20px auto;
    .t2 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      color: #111925;
      font-weight: bolder;

      line-height: 1.6;
    }
    .t3 {
      margin-bottom: 1.125rem;
      font-size: 0.825rem;
      font-weight: 500;
      line-height: 1.6;
      color: #646971;
    }
    .videoBody {
      height: 362px;
    }
  }
}
</style>