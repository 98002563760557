<template>
  <div class="drlyTrends">
    <div
      class="headImg"
      :style="{width:'100%',backgroundImage:'url(' +  require('@/assets/trends/head.png')+')',backgroundSize : '100% 100%'}"
    >
      <div class="head_body">
        <div class="head_title">资讯动态</div>
        <img :src=" require('@/assets/trends/more.png')" />
      </div>
    </div>
    <div class="body">
      <div class="menu">
        <div
          :class="active == '全部' ? 'menu_item active' : 'menu_item'"
          @click="() => { active = '全部'}"
        >全部</div>
        <!-- <div
          :class="active == '1' ? 'menu_item active' : 'menu_item'"
          @click="clickTag(1)"
        >{{getTagText(1)}}</div> -->
      </div>
      <div class="content">
        <card
          v-for="c in trendsData"
          :key="c.guid"
          :data="c"
        />
      </div>
    </div>
  </div>
</template>

<script>
import card from "../../components/trends/card.vue";
export default {
  name: 'DrlyTrends',
  components: {
    card
  },
  props: {},
  data() {
    return {
      active: "全部",
      trendsData: [
        {
          "guid": 1115947061030686734,
          "title": "【大人研究所】有品牌加持和标准化管理的“小吃小喝小聚”更令人安心",
          "trends_desc": "\"小吃小喝小聚\"作为一种更“轻”的餐饮趋势，日益受到消费者的青睐。其品类涵盖特色小吃、快餐简餐、咖啡茶饮、烘焙甜品、健康轻食等。伴随着消费者健康意识提升、生活节奏快、追求性价比、口味多样化需求、社交媒体影响，甚至资本青睐和技术进步，\"小吃小喝小聚\"在餐饮市场上体现出强烈的竞争力。\n\n \n\n本次调查探究了受访者对“小吃小喝小聚”餐饮类型的消费偏好、消费行为模式及期望。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/d4d47efde8a087b1e53b5ed02b481b8a.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/a7aed734faaafe1302d5d1afbf6c04e3.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/e0487b281fdd553bed3b7b1d6963f8fc.png",
          "content_link": "https://mp.weixin.qq.com/s/yv5DojedzcgWHUP5f1PXGA",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-09-15 13:25:31",
          "update_time": "2024-09-15 13:25:39"
        },
        {
          "guid": 1115947061030686733,
          "title": "【大人研究所】 数字点餐：社交焦虑的智能解药",
          "trends_desc": "随着数字时代的脚步加速，我们的日常正在被科技重塑。在餐饮领域，这一变革尤为显著，传统点餐模式已不再是唯一选择。消费者现在追求的，是一种能够提供快速、便捷、且高度个性化的用餐体验，而数字化点餐正满足了这些期待。它不仅提升了餐饮业的运营效率，更通过减少面对面交流，为顾客带来了一种全新的轻松用餐方式。\n\n \n\n在本次调研中，我们搜集了数字化点餐相较于传统方式的优势以及现有的技术问题。调研数据显示，绝大多数受访者（67.83%）认为数字化转型对餐饮业至关重要。数字化点餐不仅因其便捷性和快速性（93.91%）受到青睐，更因其提供的透明化菜品信息和定价（74.78%）而备受好评。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/37a93c9b06bd5b680d6fcac3dad13f2e.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-09/image/8b2adcc2337cafb1605e9d31b6d06541.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/844a145128ff7ef106bf4b14b6421769.png",
          "content_link": "https://mp.weixin.qq.com/s/vxVr9aSKvSzyJcjEDTzLwg",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-09-08 16:21:31",
          "update_time": "2024-09-08 16:21:40"
        },
        {
          "guid": 1115947061030686732,
          "title": "【大人研究所】“不健康食品”健康化，抓住“热爱多巴胺”的人群",
          "trends_desc": "古语“民以食为天”强调了食物在人们生活中的核心地位，而现代科学也进一步证实了食品选择与健康息息相关。尽管人们越来越意识到健康饮食的重要性，但“不健康食品”仍然具有强大的吸引力。本次调研通过收集不同受访者的消费习惯、对健康饮食的认知以及影响消费选择的因素等数据。通过这些数据，我们旨在全面了解健康食品在现代生活中的普及程度，同时探索“不健康食品”健康化趋势的需求及必要性，以满足公众对健康饮食选择的需求。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-26/image/b0823235da8893593c4efa22ccaa396e.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-26/image/3bd969511d5be247804a17a75f4f320e.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-26/image/012db6c51221af01dfcd25dc3d34f095.png",
          "content_link": "https://mp.weixin.qq.com/s/I1vb6_JLdXtmm6rGDn8Tsw",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-08-25 10:10:00",
          "update_time": "2024-08-25 10:10:09"
        },
        {
          "guid": 1115947061030686731,
          "title": "【大人研究所】社交媒体正在重塑餐饮消费者吐槽方式",
          "trends_desc": "在餐饮行业的繁荣发展中，消费者的反馈起着至关重要的作用。积极的反馈可以增强品牌与消费者的粘性，而消极的反馈，即吐槽，则给企业提供了提升的方向和机会。身为消费者，你爱吐槽吗？你在哪里吐槽？你的吐槽有效吗？",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-19/image/9419ab12699247470981fca2210c77bc.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-19/image/7b5f634539dd2d698adf6c5be9c4f676.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-19/image/c7108ed8483be035ebd6f67563eb7d93.png",
          "content_link": "https://mp.weixin.qq.com/s/9EnFqmU3XhxU5T_EUOILFA",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-08-18 14:48:25",
          "update_time": "2024-08-18 14:48:31"
        },
        {
          "guid": 1115947061030686730,
          "title": "【大人研究所】30分钟是餐厅等位可接受度的参考分界线",
          "trends_desc": "随着餐饮市场的不断发展和消费者需求的多样化，了解消费市场、消费者的行为和偏好对于餐饮企业至关重要。本次调研通过大人来也平台进行问卷发放，深入了解餐饮市场的市场趋势、消费者的餐饮偏好以及影响餐饮选择的决策因素。 \n\n本次调研涵盖了性别、外出用餐频率、年龄层次、用餐同伴、餐厅选择因素、信息获取途径、等位态度、点餐偏好、菜品类型偏好、口味偏好、菜单形式影响支付方式选择以及工作餐解决方式等多个维度，以下信息为本次调研的主要发现成果，供相关读者参考。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-12/image/c1d9f45c4233c38ef6b8e6e501b2c0a7.jpg",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-12/image/6e00066670a244ecf720b590ce638627.jpg",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-12/image/317aa74f164af7d6d6106ffb97f0f9e5.jpg",
          "content_link": "https://mp.weixin.qq.com/s/Ah-CQ3EHBfy3nJlrPLs7vA",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-08-11 13:19:09",
          "update_time": "2024-08-11 13:19:15"
        },
        {
          "guid": '1115947061030686721',
          "title": "餐饮促销活动吸引力调查问卷",
          "trends_desc": "帮助餐饮从业者了解消费者对促销活动的偏好、期望和接受度，以便设计出更具吸引力和有效性的促销活动，提升客流量和销售额。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f24160dbfd381fdfc13e56b24597a9f7.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/eec346128f07494675d777439aab70ec.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/e65e0e6a421a57cb81134cd0881d9596.png",
          "content_link": 141,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:16:57",
          "update_time": "2024-07-25 17:16:57"
        },
        {
          "guid": '1115947061030686722',
          "title": "餐厅食品安全信任度调查问卷",
          "trends_desc": "了解消费者对餐厅食品安全的信任度以及影响信任度的因素，以便餐饮从业者采取相应措施提升食品安全水平，增强消费者信心。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/6d3a786c64d9a5655765690701a3a669.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/edd1613589dd6793ac36fa7285772141.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f11b8c78ddfed23851a1724c827cd96a.png",
          "content_link": 142,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686723',
          "title": "餐饮品牌社交媒体销量提升调查问卷",
          "trends_desc": "了解消费者对餐饮品牌在社交媒体上的内容、活动和互动方式的看法和偏好，以便餐饮品牌更好地利用社交媒体提升品牌知名度和销量。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/a4fc6c497699ee02a3ea1a2a818261f4.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/71359250e02754b980c158cbf6977b2f.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/d9cec53d9256df3e28ab4fc52e959a56.png",
          "content_link": 143,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686724',
          "title": "餐饮企业数字化转型调查问卷",
          "trends_desc": "了解消费者对餐饮企业数字化转型的看法、体验和期望，以便餐饮企业在转型过程中更好地满足消费者需求，提升服务质量，并优化运营流程。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/3eb454183ea49e19ed761fe25af0cc5b.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/ee9927baa8389d493c5b03f0b412ab13.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/639f0fb053c5479d5d0527da17b26c80.png",
          "content_link": 144,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686725',
          "title": "健康餐饮趋势调查问卷",
          "trends_desc": "了解消费者对健康餐饮趋势的认知、态度和期望，以便餐饮从业者更好地满足市场需求，提升健康餐饮的服务质量和消费者满意度。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/ad4bc1c35c8a9ae7307eccea9795df3d.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f2221b02ae210a4a93565ee56cdb98c9.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/a5f52659d1f65c1f21e86b2c7ebaf7b2.png",
          "content_link": 145,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686726',
          "title": "酒店智能化技术消费者调查问卷",
          "trends_desc": "帮助酒店经营者了解消费者对智能化技术和个性化服务的需求，以便更好地满足客户需求并提升服务质量。感谢您的参与和支持！",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/08ceefad179c2fb0e5ac9e8275022821.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/d230f8ed21ccfa01720c00e79ffbc353.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/40c5c303173e849c0e465ae2018dde30.png",
          "content_link": 146,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686727',
          "title": "连锁餐饮企业小程序会员系统消费者看法调查问卷",
          "trends_desc": "帮助连锁餐饮企业经营者了解消费者对小程序会员系统的看法，以便优化会员系统，提升消费者满意度。感谢您的参与和支持！",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-26/image/1f871e595ce4ef898611d80f6c82ecb8.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/3d7b0a13aaee4d884a6ea4bbc1dd0963.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/2fb75c5a2b82bd26d96a1501ffa07723.png",
          "content_link": 147,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686728',
          "title": "民宿消费者权益调查问卷",
          "trends_desc": "了解民宿市场中最常见的消费者权益问题，以便采取相应措施保护消费者权益。感谢您的参与和支持！",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/83299cd71ed1a0c608ded87de66069c6.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/e6525086237728898867fb170ecfa952.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/284cc0ca6330668346d973d8251d4235.png",
          "content_link": 148,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        }, {
          "guid": 1115947061030686729,
          "title": "【大人研究所】“自由行”和“周内游”是当下旅行习惯两大关键词",
          "trends_desc": "最近一年，国内文旅市场展现出强劲的复苏势头。传统节假日期间的旅游市场更是异常火爆，各类文化活动和特色表演极大地丰富了游客的旅游体验。此外，随着全域旅游的深入推进，越来越多的地区开始探索文旅融合的新模式，将当地的文化特色与旅游资源相结合，为游客提供了更加多元化和个性化的旅游选择。\n大人来也在3个月内回收了3150份针对平台用户的旅游习惯调查问卷，受访者遍布全国，来了解一下我们用户的旅行习惯吧！",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-01/image/4a4099c6d5c04d51eba0cb804908dee8.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-01/image/27e72c7b6911d2e5d84ef317ef7d4687.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-01/image/305b70b676d56eab2d19c0dc9e4328bc.png",
          "content_link": "https://mp.weixin.qq.com/s/V1B1_izDuW0X1LZjsL_DnA",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-08-04 14:07:34",
          "update_time": "2024-08-04 14:07:36"
        },

      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    clickTag(ac) {
      this.active = ac;
    },
    getTagText(tag) {
      if (tag == 1) {
        return "宣传推广";
      }
      return "";
    },

  },
  created() { },
  mounted() {

  }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .drlyTrends {
    margin-top: 80px;
    .headImg {
      height: 300px;
      .head_body {
        width: calc(100% - 40px);
        padding: 0px 20px;
        max-width: 1280px;
        margin: 0px auto;
        padding-top: 80px;
        height: calc(100% - 80px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head_title {
          font-size: 2.5rem;

          font-weight: bolder;
          color: #111925;
        }
        img {
          height: 200px;
        }
      }
    }
    .body {
      width: calc(100% - 40px);
      padding: 0px 20px;
      max-width: 1280px;
      margin: 0px auto;
      display: flex;
      justify-content: space-between;
      .menu {
        width: 148px;
        height: 318px;
        position: sticky;
        margin-top: 20px;
        top: 100px;
        .menu_item {
          text-align: center;
          border-radius: 0.375rem;
          font-size: 0.875rem;
          height: 2.25rem;
          width: 9.25rem;
          cursor: pointer;
          margin-top: 12px;
          line-height: 2.25rem;
          color: #111925;
          background-color: #f3f3f3;
          font-weight: 400;
        }
        .active {
          background-color: #ff715f;
        }
      }
      .content {
        margin-top: 20px;
        width: calc(100% - 208px);
      }
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .drlyTrends {
    margin-top: 60px;
    .headImg {
      height: 300px;
      .head_body {
        width: calc(100% - 40px);
        padding: 0px 20px;
        max-width: 1280px;
        margin: 0px auto;
        padding-top: 80px;
        height: calc(100% - 80px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head_title {
          font-size: 2.5rem;

          font-weight: bolder;
          color: #111925;
        }
        img {
          height: 200px;
        }
      }
    }
    .body {
      width: calc(100% - 40px);
      padding: 0px 20px;
      max-width: 1280px;
      margin: 0px auto;
      display: flex;
      justify-content: space-between;
      .menu {
        width: 148px;
        height: 318px;
        position: sticky;
        margin-top: 20px;
        top: 100px;
        .menu_item {
          text-align: center;
          border-radius: 0.375rem;
          font-size: 0.875rem;
          height: 2.25rem;
          width: 9.25rem;
          cursor: pointer;
          margin-top: 12px;
          line-height: 2.25rem;
          color: #111925;
          background-color: #f3f3f3;
          font-weight: 400;
        }
        .active {
          background-color: #ff715f;
        }
      }
      .content {
        margin-top: 20px;
        width: calc(100% - 208px);
      }
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .drlyTrends {
    margin-top: 60px;
    .headImg {
      height: 186px;
      .head_body {
        width: calc(100% - 40px);
        padding: 0px 20px;
        max-width: 1280px;
        margin: 0px auto;
        padding-top: 80px;
        height: calc(100% - 80px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head_title {
          font-size: 1.25rem;
          font-weight: bolder;
          color: #111925;
        }
        img {
          height: 100px;
        }
      }
    }
    .body {
      width: calc(100% - 40px);
      padding: 0px 20px;
      max-width: 1280px;
      margin: 0px auto;
      .menu {
        width: 148px;
        height: 318px;
        position: sticky;
        margin-top: 20px;
        top: 100px;
        display: none;
        .menu_item {
          text-align: center;
          border-radius: 0.375rem;
          font-size: 0.875rem;
          height: 2.25rem;
          width: 9.25rem;
          cursor: pointer;
          margin-top: 12px;
          line-height: 2.25rem;
          color: #111925;
          background-color: #f3f3f3;
          font-weight: 400;
        }
        .active {
          background-color: #ff715f;
        }
      }
      .content {
        margin-top: 20px;
        width: calc(100% - 0px);
      }
    }
  }
}
</style>