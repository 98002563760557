<template>
  <div
    class="drInstitute"
    style="background-color: #f7f8f9;"
  >

    <div
      class="bH"
      :style="{ backgroundImage: 'url(' + require('@/assets/home/bh_center.svg') + ')' }"
    >
      <div
        class="bh_left"
        :style="{ backgroundImage: 'url(' + require('@/assets/home/bh_left.svg') + ')' }"
      />

      <div
        class="bh_right"
        :style="{ backgroundImage: 'url(' + require('@/assets/home/bh_right.svg') + ')' }"
      ></div>

      <div class="body">
        <div class="title">
          大人来也致力于为客户提供真实、有效的实证调查
        </div>
        <div class="sub_title">
          大人研究所的合作伙伴包括高校、研究机构、行业协会、连锁企业、咨询智库，研究合作请通过邮件联系我们 <span
            class="email"
            @click="mailto('marketing@huami-tech.com')"
          >marketing@huami-tech.com</span>
        </div>
        <div class="sub_title2">
          <div> 大人研究所的合作伙伴</div>
          <div>包括高校、研究机构、行业协会、连锁企业、咨询智库，</div>
          <div>研究合作请通过邮件联系我们 <span
              class="email"
              @click="mailto('marketing@huami-tech.com')"
            >marketing@huami-tech.com</span></div>
        </div>
        <div class="desc">
        </div>
        <div
          class="desc_show_btn"
          @click="showDesc()"
        ></div>
        <div class="desc2">

        </div>
        <img
          width="100%"
          :src=" require('@/assets/dr_institute/head.png')"
        />
      </div>
    </div>

    <trends
      :data="trendsData"
      @showAll="() => { 
      $router.push('/dryjsTrends')
    }"
    />
  </div>

</template>

<script>
import trends from '@/components/home/trends.vue';


export default {
  components: {
    trends
  },
  props: {},
  data() {
    return {
      trendsData: [
        {
          "guid": 1115947061030686734,
          "title": "【大人研究所】有品牌加持和标准化管理的“小吃小喝小聚”更令人安心",
          "trends_desc": "\"小吃小喝小聚\"作为一种更“轻”的餐饮趋势，日益受到消费者的青睐。其品类涵盖特色小吃、快餐简餐、咖啡茶饮、烘焙甜品、健康轻食等。伴随着消费者健康意识提升、生活节奏快、追求性价比、口味多样化需求、社交媒体影响，甚至资本青睐和技术进步，\"小吃小喝小聚\"在餐饮市场上体现出强烈的竞争力。\n\n \n\n本次调查探究了受访者对“小吃小喝小聚”餐饮类型的消费偏好、消费行为模式及期望。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/d4d47efde8a087b1e53b5ed02b481b8a.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/a7aed734faaafe1302d5d1afbf6c04e3.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/e0487b281fdd553bed3b7b1d6963f8fc.png",
          "content_link": "https://mp.weixin.qq.com/s/yv5DojedzcgWHUP5f1PXGA",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-09-15 13:25:31",
          "update_time": "2024-09-15 13:25:39"
        },
        {
          "guid": 1115947061030686733,
          "title": "【大人研究所】 数字点餐：社交焦虑的智能解药",
          "trends_desc": "随着数字时代的脚步加速，我们的日常正在被科技重塑。在餐饮领域，这一变革尤为显著，传统点餐模式已不再是唯一选择。消费者现在追求的，是一种能够提供快速、便捷、且高度个性化的用餐体验，而数字化点餐正满足了这些期待。它不仅提升了餐饮业的运营效率，更通过减少面对面交流，为顾客带来了一种全新的轻松用餐方式。\n\n \n\n在本次调研中，我们搜集了数字化点餐相较于传统方式的优势以及现有的技术问题。调研数据显示，绝大多数受访者（67.83%）认为数字化转型对餐饮业至关重要。数字化点餐不仅因其便捷性和快速性（93.91%）受到青睐，更因其提供的透明化菜品信息和定价（74.78%）而备受好评。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/37a93c9b06bd5b680d6fcac3dad13f2e.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-09/image/8b2adcc2337cafb1605e9d31b6d06541.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/844a145128ff7ef106bf4b14b6421769.png",
          "content_link": "https://mp.weixin.qq.com/s/vxVr9aSKvSzyJcjEDTzLwg",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-09-08 16:21:31",
          "update_time": "2024-09-08 16:21:40"
        },
        {
          "guid": '1115947061030686721',
          "title": "餐饮促销活动吸引力调查问卷",
          "trends_desc": "帮助餐饮从业者了解消费者对促销活动的偏好、期望和接受度，以便设计出更具吸引力和有效性的促销活动，提升客流量和销售额。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f24160dbfd381fdfc13e56b24597a9f7.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/eec346128f07494675d777439aab70ec.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/e65e0e6a421a57cb81134cd0881d9596.png",
          "content_link": 141,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:16:57",
          "update_time": "2024-07-25 17:16:57"
        },
        {
          "guid": '1115947061030686722',
          "title": "餐厅食品安全信任度调查问卷",
          "trends_desc": "了解消费者对餐厅食品安全的信任度以及影响信任度的因素，以便餐饮从业者采取相应措施提升食品安全水平，增强消费者信心。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/6d3a786c64d9a5655765690701a3a669.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/edd1613589dd6793ac36fa7285772141.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f11b8c78ddfed23851a1724c827cd96a.png",
          "content_link": 142,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686723',
          "title": "餐饮品牌社交媒体销量提升调查问卷",
          "trends_desc": "了解消费者对餐饮品牌在社交媒体上的内容、活动和互动方式的看法和偏好，以便餐饮品牌更好地利用社交媒体提升品牌知名度和销量。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/a4fc6c497699ee02a3ea1a2a818261f4.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/71359250e02754b980c158cbf6977b2f.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/d9cec53d9256df3e28ab4fc52e959a56.png",
          "content_link": 143,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686724',
          "title": "餐饮企业数字化转型调查问卷",
          "trends_desc": "了解消费者对餐饮企业数字化转型的看法、体验和期望，以便餐饮企业在转型过程中更好地满足消费者需求，提升服务质量，并优化运营流程。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/3eb454183ea49e19ed761fe25af0cc5b.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/ee9927baa8389d493c5b03f0b412ab13.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/639f0fb053c5479d5d0527da17b26c80.png",
          "content_link": 144,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686725',
          "title": "健康餐饮趋势调查问卷",
          "trends_desc": "了解消费者对健康餐饮趋势的认知、态度和期望，以便餐饮从业者更好地满足市场需求，提升健康餐饮的服务质量和消费者满意度。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/ad4bc1c35c8a9ae7307eccea9795df3d.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f2221b02ae210a4a93565ee56cdb98c9.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/a5f52659d1f65c1f21e86b2c7ebaf7b2.png",
          "content_link": 145,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686726',
          "title": "酒店智能化技术消费者调查问卷",
          "trends_desc": "帮助酒店经营者了解消费者对智能化技术和个性化服务的需求，以便更好地满足客户需求并提升服务质量。感谢您的参与和支持！",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/08ceefad179c2fb0e5ac9e8275022821.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/d230f8ed21ccfa01720c00e79ffbc353.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/40c5c303173e849c0e465ae2018dde30.png",
          "content_link": 146,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686727',
          "title": "连锁餐饮企业小程序会员系统消费者看法调查问卷",
          "trends_desc": "帮助连锁餐饮企业经营者了解消费者对小程序会员系统的看法，以便优化会员系统，提升消费者满意度。感谢您的参与和支持！",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-26/image/1f871e595ce4ef898611d80f6c82ecb8.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/3d7b0a13aaee4d884a6ea4bbc1dd0963.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/2fb75c5a2b82bd26d96a1501ffa07723.png",
          "content_link": 147,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": '1115947061030686728',
          "title": "民宿消费者权益调查问卷",
          "trends_desc": "了解民宿市场中最常见的消费者权益问题，以便采取相应措施保护消费者权益。感谢您的参与和支持！",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/83299cd71ed1a0c608ded87de66069c6.png",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/e6525086237728898867fb170ecfa952.png",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/284cc0ca6330668346d973d8251d4235.png",
          "content_link": 148,
          "tag": "",
          "type": "2",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-07-25 17:49:11",
          "update_time": "2024-07-25 17:49:11"
        },
        {
          "guid": 1115947061030686730,
          "title": "【大人研究所】30分钟是餐厅等位可接受度的参考分界线",
          "trends_desc": "随着餐饮市场的不断发展和消费者需求的多样化，了解消费市场、消费者的行为和偏好对于餐饮企业至关重要。本次调研通过大人来也平台进行问卷发放，深入了解餐饮市场的市场趋势、消费者的餐饮偏好以及影响餐饮选择的决策因素。 \n\n本次调研涵盖了性别、外出用餐频率、年龄层次、用餐同伴、餐厅选择因素、信息获取途径、等位态度、点餐偏好、菜品类型偏好、口味偏好、菜单形式影响支付方式选择以及工作餐解决方式等多个维度，以下信息为本次调研的主要发现成果，供相关读者参考。",
          "head_image": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-12/image/c1d9f45c4233c38ef6b8e6e501b2c0a7.jpg",
          "head_image_max": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-12/image/6e00066670a244ecf720b590ce638627.jpg",
          "head_image_min": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-08-12/image/317aa74f164af7d6d6106ffb97f0f9e5.jpg",
          "content_link": "https://mp.weixin.qq.com/s/Ah-CQ3EHBfy3nJlrPLs7vA",
          "tag": "",
          "type": "1",
          "opt_status": 1,
          "is_delete": 0,
          "create_time": "2024-08-11 13:19:09",
          "update_time": "2024-08-11 13:19:15"
        }
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    mailto(url) {
      window.location.href = 'mailto:' + url;
    },
    showDesc() {
      console.log(this.$(".desc2"));
      this.$(".desc_show_btn").css('display', 'none');
      this.$(".desc2").css('display', 'block');
    }
  },
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .bH {
    max-height: 542px;
    padding-top: 80px;
    .body {
      max-height: 462px;
      .desc2 {
        display: none;
      }
      .desc_show_btn {
        display: none;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .bH {
    max-height: 522px;

    padding-top: 60px;
    .body {
      max-height: 462px;
      .desc2 {
        display: none;
      }
      .desc_show_btn {
        display: none;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .bH {
    .bh_left {
      display: none;
    }
    .bh_right {
      display: none;
    }
    .body {
      //   padding-top: 3rem !important;
      padding-bottom: 0px !important;
      .title {
        font-size: 1.5rem !important;
      }
      .sub_title {
        display: none !important;
        .email {
          text-decoration: underline;
          color: #4a8ee9;
        }
        .email:hover {
          cursor: pointer;
          text-decoration: underline;
          color: #4a8ee9;
        }
      }
      .sub_title2 {
        display: block !important;
        margin: 0px auto;
        margin-top: 32px;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #111925;
        text-align: center;
        width: 90%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        .email {
          text-decoration: underline;
          color: #4a8ee9;
        }
        .email:hover {
          cursor: pointer;
          text-decoration: underline;
          color: #4a8ee9;
        }
      }
      .desc {
        display: none !important;
      }
      .desc2 {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: rgba(15, 25, 37, 0.65);
        text-align: center;
        width: 90%;
        margin: 0px auto;
        margin-top: 2em;
        display: none;
      }
      .desc_show_btn {
        padding-bottom: 30px;
        padding-top: 1.5rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        display: block;
        text-align: center;
        i {
          margin-left: 3px;
        }
      }
    }
  }
}

.bH {
  background-color: #f7f8f9;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: top;
  background-size: auto 400px;
  background-repeat: no-repeat;
  .bh_left {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 400px;

    height: 400px;
  }
  .bh_center {
    position: absolute;
    top: -10%;
    left: 0%;
    width: 100%;
  }
  .bh_right {
    position: absolute;
    top: 0px;
    right: -20px;
    height: 400px;
    width: 200px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .body {
    min-height: 362px;
    max-width: 1280px;
    margin: 0px auto;
    position: relative;
    padding-top: 3.75rem;
    padding-bottom: 2rem;
    z-index: 1;
    background: transparent;
    .title {
      font-size: 3.25rem;
      line-height: 1em;
      text-align: center;
      color: #111925;
      font-weight: bolder;
    }
    .sub_title {
      margin: 0px auto;
      margin-top: 32px;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: #111925;
      font-weight: bolder;
      width: 90%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      .email {
        text-decoration: underline;
        color: #4a8ee9;
      }
      .email:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #4a8ee9;
      }
    }
    .sub_title2 {
      display: none;
      .email {
        text-decoration: underline;
        color: #4a8ee9;
      }
      .email:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #4a8ee9;
      }
    }
    .desc {
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: rgba(15, 25, 37, 0.65);
      text-align: center;
      width: 90%;
      margin: 0px auto;
      margin-top: 2em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
}
</style>