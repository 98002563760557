<template>
  <div class="footer_nav">

    <item
      v-for="item in data"
      :key="item.title"
      :data="item"
    />

  </div>
</template>

<script>
import item from './footer_nav_item.vue';
export default {
  components: {
    item,
  },
  props: {
    data: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
.footer_nav {
  display: flex;
  justify-content: end;
}
</style>