<template>
  <div class="footer">
    <div class="pc">
      <div class="body">
        <img
          :src="logo"
          width="130"
          height="47"
        />
        <div class="nav">
          <footer-nav :data="navData" />
        </div>
      </div>
    </div>
    <div class="h5"></div>

    <copyright />
  </div>
</template>

<script>
import footer_nav from './footer/footer_nav.vue';
import copyright from './footer/copyright.vue'
export default {
  components: {
    "footer-nav": footer_nav,
    copyright
  },
  props: {},
  data() {
    return {
      logo: require('../assets/logo.png'),
      navData: [
        {
          title: "关于我们",
          list: [
            {
              title: "公司简介",
              isLink: true,
              click: () => {
                window.open('https://mp.weixin.qq.com/s/M5XOSh_ZgwgaGe3PTFUDzg');
              }
            }
          ]
        },
        {
          title: "下载和关注",
          list: [
            {
              title: "大人来也APP",
              isPop: true,
              img: require('../assets/header/download_app.png')
            },
            {
              title: "大人来也调研小程序",
              isPop: true,
              img: require('../assets/footer/drly_diaoYan.png')
            },
            {
              title: "大人来也公众号",
              isPop: true,
              img: require('../assets/footer/drly_GongZhong.jpg')
            },
            {
              title: "商户端小程序",
              isPop: true,
              img: require('../assets/footer/drly_biz.png')
            },
            {
              title: "99尝鲜试单", isPop: true,
              img: require('../assets/footer/drly_99.png')
            },
            {
              title: "大人来也视频号", isPop: true,
              img: require('../assets/footer/drly_ShiPin.png')
            },

          ]
        }
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .footer {
    background-color: #f3f3f4;
    .pc {
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      padding-top: 3rem;
      .body {
        display: flex;
        justify-content: space-between;
      }
    }
    .h5 {
      display: none;
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .footer {
    background-color: #f3f3f4;
    .pc {
      display: none;
    }
    .h5 {
    }
  }
}
</style>