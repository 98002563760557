<template>
  <el-drawer
    title=""
    :visible.sync="show"
    :direction="'ltr'"
    :modal-append-to-body="false"
    :show-close="false"
    @closed="close"
    :size="'220px'"
  >

    <div style="background-color: #FFF; position: fixed;
  top: 50px;
  left: 0;
  width: 220px;">
      <img
        :src="logo"
        width="60%"
        style="margin-left: 20%;  "
      />
    </div>
    <div class="nav">
      <div
        class="nav_item"
        :style="{color:$route.path !== '/' ? 'normal' : '#ee4b37'}"
        @click="jump('首页','/')"
      >首页</div>
      <!-- <div class="nav_item">商户注册</div> -->
      <div
        class="nav_item"
        :style="{color:$route.path !== '/businessHandbook' ? 'normal' : '#ee4b37'}"
        @click="jump( '商户使用手册','/businessHandbook')"
      >商户使用手册</div>
      <div
        class="nav_item"
        :style="{color:$route.path !== '/drInstitute' ? 'normal' : '#ee4b37'}"
        @click="jump( '大人研究所','/drInstitute')"
      >大人研究所</div>
      <div
        class="nav_item"
        :style="{color:$route.path !== '/contactUs' ? 'normal' : '#ee4b37'}"
        @click="jump('联系我们','/contactUs')"
      >联系我们</div>
      <!-- <div class="nav_item">下载用户APP</div>
      <div class="nav_item">关注大人来也</div> -->
    </div>
  </el-drawer>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      show: true,

      logo: require('../assets/logo.png'),
    };
  },
  watch: {},
  computed: {},
  methods: {
    jump(ac, path) {

      if (this.$route.path !== path) {
        // this.active = ac;
        this.$router.push(path)
        this.close();
      }

    },
    close() {
      this.$emit("close");
    }
  },
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
.nav {
  margin-left: 20%;
  margin-bottom: 92px;
  margin-top: 90px;
  .nav_item {
    color: #000;
    font-size: 14px;
    margin-top: 15px;
    padding-bottom: 15px;
  }
}
</style>