<template>
  <div class="contactUs">
    <div class="t1">服务热线</div>
    <div class="t2">欢迎您通过邮件方式与我们取得联络</div>

    <div class="body">

      <us-card
        :title="'商务合作'"
        :desc="'biz@huami-tech.com'"
        :jump="() => { 
    mailto('biz@huami-tech.com');
        }"
      />

      <us-card
        :title="'客服支持'"
        :desc="'service@huami-tech.com'"
        :jump="() => { 
    mailto('service@huami-tech.com');
        }"
      />

      <us-card
        :title="'市场活动'"
        :desc="'marketing@huami-tech.com'"
        :jump="() => { 
    mailto('marketing@huami-tech.com');
        }"
      />

      <us-card
        :title="'第三方开发'"
        :desc="'supports@huami-tech.com'"
        :jump="() => { 
    mailto('supports@huami-tech.com');
        }"
      />

      <us-card
        :title="'人员招聘'"
        :desc="'hr@huami-tech.com'"
        :jump="() => { 
    mailto('hr@huami-tech.com');
        }"
      />

      <us-card
        :title="'其他事务'"
        :desc="'darenlaiye@huami-tech.com'"
        :jump="() => { 
    mailto('darenlaiye@huami-tech.com');
        }"
      />
      <us-card
        :title="'公司地址'"
        :desc="'上海市徐汇区虹桥路333号3幢592室'"
        :jump="() => { 
    baiduMap('上海市徐汇区虹桥路333号3幢592室');
        }"
      />

      <us-card
        :title="'官网'"
        :desc="'www.darenlaiye.com'"
        :jump="() => { 
    jump('www.darenlaiye.com');
        }"
      />

      <us-card
        :title="'服务咨询'"
        :desc="'联系微信客服'"
        :jump="() => { 
    open('https://work.weixin.qq.com/kfid/kfc2d4e8a97f9ccb607');
        }"
      />

      <us-card style="opacity: 0;" />
    </div>

    <div class="contact_us_bottom">
      <!-- https://work.weixin.qq.com/kfid/kfc2d4e8a97f9ccb607 -->
      <!-- <span
        @click=" open('https://work.weixin.qq.com/kfid/kfc2d4e8a97f9ccb607')"
        class="lianxi"
      >服务咨询联系我们<span>点击联系客服</span></span> -->

    </div>
  </div>
</template>

<script>
import card from '../components/contact_us/card.vue';
export default {
  components: { 'us-card': card },
  props: {},
  data() {
    return {
      contactUs: require('../assets/contact_us/contactUs.png'),
    };
  },
  watch: {},
  computed: {},
  methods: {
    mailto(url) {

      window.location.href = 'mailto:' + url;
    },
    baiduMap(url) {
      window.open(`http://api.map.baidu.com/geocoder?address=${url}&output=html&src=webapp.baidu.openAPIdemo`);
    },
    jump(url) {
      window.location.href = url;
    },
    open(url) {
      window.open(url);
    },
  },
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
.contactUs {
  max-width: 1280px;
  padding: 0px 20px;
  margin: 0px auto;
}

/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .contactUs {
    .t1 {
      font-size: 2.5rem;
      padding-top: 8rem;
      padding-bottom: 1.25rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 2rem;
      margin-top: 2.5rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1.25rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2.5rem;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .contactUs {
    .t1 {
      font-size: 2.5rem;
      padding-top: 8rem;
      padding-bottom: 1.25rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 2rem;
      margin-top: 2.5rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1.25rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2.5rem;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .contactUs {
    .t1 {
      font-size: 1.25rem;
      padding-top: 5rem;
      padding-bottom: 0.5rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 0.75rem;
      margin-top: 2rem;
      font-size: 1.125rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2rem;
    }
  }
}
</style>