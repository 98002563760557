<template>
  <div class="copyright">
    <div class="pc">
      <div>备案信息</div>
      <div>沪ICP备17018458号-1</div>
      <div>华米信息技术（上海）有限公司
      </div>
    </div>
    <div class="h5">
      <div>备案信息</div>
      <div>沪ICP备17018458号-1</div>
      <div>华米信息技术（上海）有限公司
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .copyright {
    border-top-color: rgb(231, 232, 233);
    border-top-style: solid;
    border-top-width: 1px;
    .pc {
      max-width: 1280px;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-top: 15px;
      margin: 0px auto;
      padding-bottom: 1.75rem;

      div {
        font-size: 0.75rem;
        color: rgba(17, 25, 37, 0.35);
        line-height: 1.15rem;
        margin-bottom: 0.25rem;
        text-align: center;
      }
    }
    .h5 {
      display: none;
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .copyright {
    border-top-color: rgb(231, 232, 233);
    border-top-style: solid;
    border-top-width: 0px;
    .pc {
      display: none;
    }
    .h5 {
      max-width: 1280px;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-top: 15px;
      margin: 0px auto;
      padding-bottom: 1.75rem;

      div {
        font-size: 0.75rem;
        color: rgba(17, 25, 37, 0.35);
        line-height: 1.15rem;
        margin-bottom: 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>