<template>
  <div class="article">

    <iframe
      src="https://mp.weixin.qq.com/s/M5XOSh_ZgwgaGe3PTFUDzg"
      style="width: 100%;"
    ></iframe>

  </div>
</template>

<script>
export default {
  components: {
  },
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
</style>