<template>
  <div class="business_handbook">
    <!-- <div class="pdf-container">
      <canvas ref="pdfCanvas"></canvas>
    </div> -->
    <pdf
      :src="pdfUrl"
      @num-pages="numPagesHandler"
      :style="{height:getWidth()}"
    ></pdf>
    <template v-for="pageNumber in numPages">
      <pdf
        :src="pdfUrl"
        v-if="pageNumber > 1"
        :key="pageNumber"
        :page="pageNumber"
        @num-pages="numPagesHandler"
        :style="{height:getWidth()}"
      ></pdf>
    </template>

    <!-- <pdf :src="pdfUrl"></pdf> -->
    <!-- 
    <pdf
      :style="{height:getWidth()}"
      :src="pdfUrl"
      :page="1"
    ></pdf> -->

    <!--<pdf
      :src="pdfUrl"
      :page="2"
    ></pdf> -->
  </div>
</template>

<script>
import pdf from 'vue-pdf';
export default {
  components: {
    pdf
  },
  props: {},
  data() {
    return {
      numPages: 1,
    };
  },
  watch: {},
  computed: {
    pdfUrl() {
      if (location.href.indexOf("media.darenlaiye.com") >= 0) {
        return "https://media.darenlaiye.com/darenlaiye/share/%E3%80%90%E5%95%86%E6%88%B7%E4%BD%BF%E7%94%A8%E3%80%91%E5%A4%A7%E4%BA%BA%E6%9D%A5%E4%B9%9F%E5%95%86%E6%88%B7%E7%AB%AF%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.pdf"
      }
      if (location.href.indexOf("darenlaiye.com") >= 0) {
        return "https://www.darenlaiye.com/darenlaiye/share/%E3%80%90%E5%95%86%E6%88%B7%E4%BD%BF%E7%94%A8%E3%80%91%E5%A4%A7%E4%BA%BA%E6%9D%A5%E4%B9%9F%E5%95%86%E6%88%B7%E7%AB%AF%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.pdf"
      }
      return "/pdf-proxy/darenlaiye/share/%E3%80%90%E5%95%86%E6%88%B7%E4%BD%BF%E7%94%A8%E3%80%91%E5%A4%A7%E4%BA%BA%E6%9D%A5%E4%B9%9F%E5%95%86%E6%88%B7%E7%AB%AF%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.pdf";
    }
  },
  methods: {
    numPagesHandler(total) {
      if (total) {
        if (this.numPages < total) {
          this.numPages += 1;
        }
        // this.numPages = 2;
      }
    },
    getWidth() {
      if (document.getElementsByClassName("business_handbook").length > 0) {
        return (document.getElementsByClassName("business_handbook")[0].offsetWidth * 1.4142421875) + "px";
      } else {
        return 0 + "px"
      }
    }
    ,
  },
  created() { },
  mounted() {
  }
};
</script>
<style lang='less' scoped>
.business_handbook {
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;
  margin-top: 80px;
  padding-bottom: 20px;
  .pdf-container {
    width: 100%;
    height: 500px; /* Adjust as needed */
  }

  canvas {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>